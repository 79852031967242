import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './request.js'
import Vant from 'vant';
import 'vant/lib/index.css';
import { Lazyload } from 'vant';

Vue.use(Vant).use(Lazyload);
import VueI18n from 'vue-i18n'

Vue.use(VueI18n).use(request)
import zh_CN from './i18n/zh_CN'
import zh_TW from './i18n/zh_TW'
import en from './i18n/en'
import common from "@/common/common";
import '@/common/common.css';
import eventbus from "@/eventbus";
Vue.use(eventbus)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

const i18n = new VueI18n(
    {
        locale: common.getLang(),
        fallbackLocale: 'en',
        messages: {
            en,
            zh_TW,
            zh_CN
        }
    }
);
Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n: i18n,
    render: h => h(App)
}).$mount('#app')
