<template>
  <div>
    <van-popup v-model="showModel" round position="bottom">
      <van-picker
          show-toolbar
          value-key="name"
          :confirm-button-text="$t('confirm')"
          :columns="actions"
          @cancel="createPlaylist"
          @confirm="onSelect"
      >
        <template v-slot:cancel>
          <button type="button" class="cancel">{{$t('playlist.create')}}</button>
        </template>
      </van-picker>
    </van-popup>
    <create-playlist-dialog ref="createDialog" @created="onCreated" @create-failed="onCreatFailed"/>
  </div>
</template>

<script>
import {Toast} from 'vant';
import CreatePlaylistDialog from "@/components/playlist/CreatePlaylistDialog";

export default {
  name: "PlaylistPicker",
  components: {CreatePlaylistDialog},
  data() {
    return {
      showModel: false,
      song: {
        songName: ''
      },
      actions: [
        {name: 'default', uid: 'xxxxx'}
      ],
    }
  },
  mounted() {

  },
  methods: {
    show(song) {
      this.song = song
      this.loadList()
    },
    loadList() {
      this.actions = [{name: 'default'}];
      this._post('/bestmv/playlist/list', {
        pageNo: 1,
        pageSize: 100,
        orderByColumn: 'id',
        orderByIsAsc: false
      }, (resp) => {
        let result = resp.data;

        this.showModel = true
        let playList = result.data;
        playList.forEach((l) => {
          this.actions.push({name: l.listName, uid: l.uid})
        });
      })
    },
    onSelect(item) {
      this._post('/bestmv/playlist/item', {listUid: item.uid, songUid: this.song.uid}, (resp) => {
        Toast(this.$t('schema.add-success'));
      })
      this.showModel = false
    },
    createPlaylist() {
      this.showModel = false
      this.$refs.createDialog.showDialog(null)
    },
    onCreated() {
      this.loadList()
      this.showModel = true
    },
    onCreatFailed() {
      this.showModel = true
    },
  }
}
</script>

<style scoped>
.content {
  padding: 16px 16px 160px;
}

.cancel {
  color: #01b182;
  background-color: transparent;
  padding: 0 16px;
  border: none;
  cursor: pointer;
  height: 100%;
}
</style>