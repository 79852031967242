import router from "@/router";
import {Dialog} from 'vant';

export default {
    hello: function () {
        console.log('hello');
    },
    isInApp() {
        try {
            return jsKit != undefined;
        } catch (e) {
            return false;
        }
    },
    getToken() {
        if (this.isInApp()) {
            return jsKit.getToken();
        } else {
            // localStorage.setItem('token', 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOjIsInVzZXJuYW1lIjoiZmgzNTkyQGdtYWlsLmNvbSIsImlhdCI6MTY3OTAzNzQzNiwiZXhwIjoxNjk0OTM1MDM2fQ.BtD6EMr53lXLbFTbKQoLXGzBR6zvzz6JZIQl_w7xH2Y');
            return localStorage.getItem('token');
        }
    },
    isLogin() {
        let token = this.getToken();
        return token != null;
    },
    goLogin() {
        if (this.isInApp()) {
            console.log('跳到登录')
            router.back()
            jsKit.showLogin();
        } else {
            router.push({path: '/login'})
        }
    },
    getUser() {
        return JSON.parse(jsKit.getUser());
    },
    share(msg, _this) {
        if (this.isInApp()) {
            jsKit.showShare(msg);
        } else {
            _this.$copyText(msg)
            Dialog.alert({
                message: msg,
                confirmButtonText: _this.$t('copy')
            }).then(() => {
                // on close
            });
        }
    },
    goMarket() {
        if (this.isInApp()) {
            jsKit.goMarket();
        }
    },
    getAppVersion() {
        return jsKit.getVersionName();
    },
    setLang(lang) {
        console.log('setLang:', lang)
        localStorage.setItem("lang", lang);
        if (this.isInApp()) {
            return jsKit.setLang(lang);
        }
    },
    getLang() {
        if (this.isInApp()) {
            return jsKit.getLang();
        }
        let lang = localStorage.getItem("lang");
        if (lang) {
            return lang;
        }
        lang = navigator.language.replace("-", "_");
        this.setLang(lang);
    },
    refreshToken(newToken) {
        if (this.isInApp()) {
            if (newToken !== null && newToken !== undefined) {
                jsKit.refreshToken(newToken);
            } else {
                jsKit.removeToken();
            }
        }
        if (newToken !== null && newToken !== undefined) {
            localStorage.setItem('token', newToken);
        } else {
            localStorage.removeItem('token');

        }
    },
    logout() {
        if (this.isInApp()) {
            jsKit.removeToken();
        } else {
            localStorage.removeItem('token');
        }
    },
    getStatusBarHeight() {
        if (this.isInApp()) {
            return jsKit.getStatusBarHeight();
        } else {
            return 0;
        }
    },
    translucentStatus() {
        if (this.isInApp()) {
            jsKit.translucentStatus();
        }
    },
    clearTranslucentStatus() {
        if (this.isInApp()) {
            jsKit.clearTranslucentStatus();
        }
    },
    //数组洗牌乱序
    shuffle(a) {
        for (let i = a.length; i; i--) {
            let j = Math.floor(Math.random() * i);
            [a[i - 1], a[j]] = [a[j], a[i - 1]];
        }
        return a;
    },
    showWindowPlayer() {
      if(this.isInApp()) {
          jsKit.showWindowPlayer()
      }
    },
    backToApp() {
        if(this.isInApp()) {
            jsKit.backToApp()
        }
    }

}