<template>
  <div id="app">
    <keep-alive include="DiscoveryView">
      <router-view/>
    </keep-alive>
    <tab-bar-player v-if="loadTabBar&&($route.path !=='/share_player' && $route.path !== '/window_player')"/>
  </div>
</template>

<script>
import common from "@/common/common";
import TabBarPlayer from "@/components/TabBarPlayer";
import {Dialog} from 'vant';

export default {
  name: 'App',
  components: {TabBarPlayer},
  mounted() {
    window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady
    window.onResume = this.onResume
    window.onStart = this.onStart
    let mvFirst = localStorage.getItem('mvFirst');
    if (mvFirst === null) {
      localStorage.setItem('mvFirst', true);
      this.$store.state.mvFirst = true
    } else {
      this.$store.state.mvFirst = (mvFirst==='true')
    }

    if (common.isLogin()) {
      this._get('/bestmv/login/refresh', (resp) => {
        let result = resp.data;
        common.refreshToken(result.data);
      });
    }
    setTimeout(() => {
      this.loadTabBar=true
    }, 100)
    setTimeout(() => {
      this.checkIframeApiReady()
    }, 12000)
  },
  data() {
    return {
      iframeApiReady: false,
      loadTabBar: false
    }
  },
  methods: {
    onYouTubeIframeAPIReady() {
      console.log('onYouTubeIframeAPIReady')
      this.iframeApiReady = true;
      this.$store.state.iframeReady = true
    },
    checkIframeApiReady() {
      if (!this.iframeApiReady) {
        Dialog.alert({
          message: this.$t('youtube.network-fail'),
          confirmButtonText:this.$t('confirm'),
        }).then(() => {
          // on close
          location.reload()
        });
      }
    },
    onResume() {
      this.$eventBus.$emitReady('onResume')
    },
    onStart(){
      this.$eventBus.$emitReady('onStart')
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
