<template>
  <div class="tabbar_player" ref="tabBarPlayer">
    <div :id="expandShow?'player_control-expand':'player_control'" v-show="playerShow" ref="player">
      <div :class="expandShow?'player_layer-expand ':'player_layer'">
        <div id="miniYtPlayer"></div>
        <div :class="expandShow?'mask_layer-expand':'mask_layer'" @click="expand"
             :style="'background-color: '+(playerReady?'transparent':'#000000')">
          <van-loading v-if="!playerReady"/>
        </div>
      </div>
      <div :class="expandShow?'control-expand':'control'">
        <div :class="expandShow?'play_info-expand':'play_info'" @click="expand">
          <div :class="expandShow?'song_info-expand':'song_info'">{{ currentSong.songName }}</div>
          <div :class="expandShow?'artist_info-expand':'artist_info'">{{ currentSong.artistName }}</div>
        </div>
        <div class="play_button" v-if="!expandShow">
          <van-icon :name="playerStateIcon" size="40" @click="playButtonClicked"/>
          <van-icon class-prefix="my-icon" name="next" size="38" style="margin-left: 10px" @click="playNext"/>
        </div>
        <div class="action" v-if="expandShow">
          <van-icon class-prefix="my-icon" name="fav" @click="addToList"/>
          <van-icon class-prefix="my-icon" name="share" @click="share"/>
          <van-icon class-prefix="my-icon" name="float" v-if="common.isInApp()" @click="showWindowPlayer"/>
          <van-icon class-prefix="my-icon" name="shrink" @click="shrink"/>
        </div>

        <div class="playlist" v-if="expandShow&&playListShow" @scroll="onPlaylistScroll" ref="playlist">
          <div class="song_select" ref="song_select">
            <div v-if="!selectMode">
              <van-button round size="small" type="info" @click="batchSelect">{{ $t('player.batch-operation') }}
              </van-button>
            </div>
            <div v-if="selectMode">
              <van-button round size="small" type="danger" @click="deleteSelected" style="margin-right: 10px">
                {{ $t('delete') }}({{ this.checkResult.length }})
              </van-button>
              <van-button round size="small" type="info" @click="selectAll" style="margin-right: 10px">
                {{ $t('player.select-all') }}
              </van-button>
              <van-button round size="small" type="info" @click="toggleAll" style="margin-right: 10px">
                {{ $t('player.select-toggle') }}
              </van-button>
              <van-button round size="small" type="info" @click="selectMode=false;checkResult=[]">{{ $t('cancel') }}
              </van-button>
            </div>
            <div v-if="!selectMode" style="display: flex;align-items:center;flex-direction: row">
              <span style="font-size: 0.8rem;margin-right: 10px">{{ $t('player.random') }}</span>
              <van-switch v-model="randomMode" size="20"/>
            </div>
          </div>
          <div ref="checkboxGroupDiv" :style="'height: '+this.playlistHeight+'px'">
            <van-checkbox-group ref="checkboxGroup" v-model="checkResult" class="checkbox_list">
              <van-checkbox v-for="(item,index) in songList" :key="item.uid" :name="index">
                <van-swipe-cell>
                  <song-item :item="item" :click-play="false" :playing="currentSong.uid===item.uid"
                             @click="itemClicked(index)"></song-item>
                  <template #right>
                    <van-button style="height: 100%" square :text="$t('delete')" type="danger" class="delete-button"
                                @click="deleteItem(index)"/>
                  </template>
                </van-swipe-cell>
              </van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
      </div>
    </div>
    <van-tabbar v-model="active" v-if="tabBarShow&&!expandShow">
      <van-tabbar-item icon="home-o">{{ $t('tabbar.discover') }}</van-tabbar-item>
      <van-tabbar-item>
        <span>{{ $t('tabbar.singer') }}</span>
        <template #icon="props">
          <van-icon class-prefix="my-icon" name="singer"/>
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="user-o">{{ $t('tabbar.account') }}</van-tabbar-item>
    </van-tabbar>
    <playlist-picker ref="playlistPicker"/>
  </div>
</template>

<script>
import SongItem from "@/components/SongItem";
import PlaylistPicker from "@/components/player/PlaylistPicker";
import common from "@/common/common";
import {Toast} from "vant";

const windowsVH = window.innerHeight / 100
document.documentElement.style.setProperty('--vh', windowsVH + 'px')



export default {
  name: "TabBarPlayer",
  components: {PlaylistPicker, SongItem},
  data() {
    return {
      active: 0,
      playerShow: false,
      tabBarShow: true,
      songList: [],
      currentIndex: 0,
      currentSong: {},
      playerState: -1,
      expandShow: false,
      adding: false,
      lastExpandTime: 0,
      playListShow: false,
      checkResult: [],
      playlistHeight: 10,
      selectMode: false,
      randomMode: false,
      playerReady: false,
      currentTime: 0,
      common
    }
  },
  computed: {
    playerStateIcon: function () {
      if (this.playerState === -1) {
        return 'music';
      }
      if (this.playerState === 0) {
        return 'play-circle';
      }
      if (this.playerState === 1) {
        return 'pause-circle';
      }
      if (this.playerState === 2) {
        return 'play-circle';
      }
      return 'pause-circle';
    }
  },
  watch: {
    active: function (val) {
      this.$eventBus.$emitReady('changeTab', val)
    },
    selectMode: function (val) {
      this.setSelectMod();
    }
  },
  mounted() {
    this.$eventBus.$onReady('addSong', this.addSong, false);
    this.$eventBus.$onReady('showPlayer', this.showPlayer, false);
    this.$eventBus.$onReady('showTabBar', this.showTabBar, false);
    this.$eventBus.$onReady('shrinkPlayer', this.shrink, false);
    this.$eventBus.$onReady('onResume', this.onResume, false);
    this.$eventBus.$onReady('onStart', this.onStart, false);
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
    this.loadSongList();
    this.loadPlayer();
    setInterval(this.savePlayPosition, 5000)
    window.addEventListener('resize', this.setTop)
  },
  destroyed() {
    console.log('destroyed')
    window.removeEventListener('popstate', this.goBack, false);
    this.$eventBus.$removeReadyEventListener('addSong', this.addSong);
    this.$eventBus.$removeReadyEventListener('onResume', this.onResume);
    this.$eventBus.$removeReadyEventListener('onStart', this.onStart);

    this.$eventBus.$removeReadyEventListener('showPlayer', this.showPlayer);
    this.$eventBus.$removeReadyEventListener('showTabBar', this.showTabBar);
    this.$eventBus.$removeReadyEventListener('shrinkPlayer', this.shrink);
    clearInterval(this.savePlayPosition)
    window.removeEventListener('resize',this.setTop)
  },
  methods: {
    loadSongList() {
      let playlistStr = localStorage.getItem("playlist")
      if (playlistStr !== null) {
        this.songList = JSON.parse(playlistStr);
        this.showPlayer(true)
        let positionStr = localStorage.getItem('playPosition')
        let time = 0;
        if (positionStr !== null) {
          let position = JSON.parse(positionStr);
          this.currentIndex = position.index
          this.currentSong = this.songList[this.currentIndex]
          time = position.time
        }
        this.currentTime = time;
      }
    },
    addSong(event) {
      if (this.adding) {
        return
      }
      this.adding = true;
      let playIndex = event.index;
      if (playIndex === undefined) {
        playIndex = 0;
      }
      let resType = event.resType;
      let clearOld = event.clearOld;
      if (clearOld) {
        this.songList = [];
        this.currentIndex = -1;
      }
      if (resType === -1) {
        //-1直接传的是歌曲
        let addSongList = event.resList;
        this.songToList(addSongList, playIndex)
        this.adding = false;
        return;
      }
      this._post('/bestmv/song/queryByType', event, (resp) => {
        console.log('resp')
        this.adding = false;
        let result = resp.data;
        if (result.code !== 200) {
          return;
        }
        let addSongList = result.data;
        this.songToList(addSongList, playIndex)
      })
    },
    songToList(addSongList, playIndex) {
      //计算真正要播放的歌曲
      let songCount = addSongList.length;
      if (playIndex >= songCount) {
        playIndex = 0
      }
      let willPlaySongUid = addSongList[playIndex].uid;
      //去重,加入
      for (let i = 0; i < addSongList.length; i++) {
        let songNeedAdd = addSongList[i];
        let addFlag = true;
        for (let j = 0; j < this.songList.length; j++) {
          let songInList = this.songList[j];
          if (songNeedAdd.uid === songInList.uid) {
            addFlag = false;
            break
          }
        }
        if (addFlag) {
          this.songList.push(songNeedAdd)
        }
      }

      for (let j = 0; j < this.songList.length; j++) {
        let songInList = this.songList[j];
        if (willPlaySongUid === songInList.uid) {
          playIndex = j;
        }
      }
      if (this.songList.length > 0) {
        this.showPlayer(true)
      }
      localStorage.setItem("playlist", JSON.stringify(this.songList));
      this.currentIndex = playIndex;
      this.currentTime = 0
      this.play();
    },

    playButtonClicked() {
      console.log('playButtonClicked',this.playerState)
      switch (this.playerState) {
        case 2:
          this.player.playVideo();
          break;
        case 1:
          this.player.pauseVideo()
          break;
      }
    },
    expand() {

      if (this.expandShow) {
        return;
      }
      if(!this.playerReady) {
        Toast(this.$t('player.loading'))
        return;
      }
      this.selectMode = false;

      this.lastExpandTime = new Date().getTime()
      this.expandShow = true;
      this.$refs.tabBarPlayer.classList.toggle('expand');
      this.$refs.tabBarPlayer.style.top = ""
      //修改 iframe size
      let playerEle = document.getElementById('miniYtPlayer')
      let playerWidth = Math.min(screen.availWidth, screen.availHeight);
      let playerHeight = playerWidth / 16 * 9;
      playerEle.width = playerWidth + 'px';
      playerEle.height = playerHeight + 'px';
      setTimeout(() => {
        this.playListShow = true;
      }, 500);
      setTimeout(() => {
        //当前播放歌曲滚动到可见位置
        document.getElementsByClassName("list_item")[this.currentIndex].scrollIntoView()
        this.setSelectMod();
        //设置高度
        if (this.playlistHeight === 10) {
          this.playlistHeight = window.innerHeight - (this.$refs.checkboxGroupDiv.offsetParent.offsetTop + this.$refs.checkboxGroupDiv.offsetTop)
        }
      }, 500)

    },
    shrink() {
      //缩回
      console.log("shrink")
      this.expandShow = false;
      this.playListShow = false;
      let playerEle = document.getElementById('miniYtPlayer');
      playerEle.width = '106px';
      playerEle.height = '60px';
      this.$refs.tabBarPlayer.classList.toggle('expand');
      this.setTop();
    },
    play() {
      if(this.songList.length===0) {
        return
      }
      this.currentSong = this.songList[this.currentIndex];
      if (!this.$store.state.iframeReady || !this.playerReady) {
        return
      }
      let ytSongId = this.currentSong.ytSongId;
      let ytVideoId = this.currentSong.ytVideoId;
      let resId = ytSongId ? ytSongId : ytVideoId;
      if (this.$store.state.mvFirst) {
        if (ytVideoId) {
          resId = ytVideoId;
        }
      }
      this.player.loadVideoById(resId, this.currentTime, 'default')
      let history = {}
      Object.assign(history, this.currentSong);
      history.songUid = this.currentSong.uid;
      this._post('/bestmv/history', history)
    },
    showPlayer(show) {
      console.log('showPlayer', show)
      this.playerShow = show;
      this.setTop();
    },
    showTabBar(show) {
      this.tabBarShow = show;
      this.setTop();
    },
    setTop() {
      console.log("setTop")
      if(this.expandShow) {
        return
      }
      let height = 0;
      if (this.playerShow) {
        height += 60;
      }
      if (this.tabBarShow) {
        height += 50;
      }
      this.$refs.tabBarPlayer.style.top = (window.innerHeight-height)+"px"
    },
    onPlayerReady() {
      console.log('onPlayerReady')
      this.playerReady = true;
      this.play()
      this.$forceUpdate()
    },
    onPlayerStateChange(event) {
      this.playerState = event.data;
      console.log('state change'+this.playerState)
      if (this.playerState === 0) {
        this.playNext();
      }
    },
    playNext() {
      let songCount = this.songList.length;
      if (this.randomMode) {
        this.currentIndex = Math.floor(Math.random() * this.songList.length)
      } else {
        this.currentIndex++;
      }
      if (this.currentIndex >= songCount) {
        this.currentIndex = 0
      }
      this.currentTime = 0
      this.play()
    },
    loadPlayer() {
      if (!this.$store.state.iframeReady) {
        setTimeout(() => {
          this.loadPlayer()
        }, 4000);
        return;
      }
      this.player = new YT.Player('miniYtPlayer', {
        height: '60px',
        width: '106px',
        events: {
          'onReady': this.onPlayerReady,
          'onStateChange': this.onPlayerStateChange,
          'onError': this.onError
        },
        playerVars: {
          "autoplay": 1,
          "controls": 1,
          "enablejsapi": 1,
          "fs": 1,
          "origin": "https://www.youtube.com",
          "rel": 0,
          "showinfo": 0,
          "iv_load_policy": 3,
          "modestbranding": 1,
          "cc_load_policy": 0
        }
      });
    },
    itemClicked(index) {
      if (this.selectMode) {
        return;
      }
      let now = new Date().getTime()
      if (now - this.lastExpandTime < 1500) {
        //容易误触发
        return;
      }
      console.log("itemClicked", index);
      this.currentIndex = index;
      this.currentTime = 0
      this.play()
    },
    deleteItem(index) {
      if (index < this.currentIndex) {
        this.currentIndex--;
        this.songList.splice(index, 1)
      } else if (index === this.currentIndex) {
        this.songList.splice(index, 1)
        if (this.currentIndex >= this.songList.length) {
          this.currentIndex = 0
        }
        if (this.songList.length > 0) {
          this.play()
        } else {
          this.player.stopVideo()
          this.shrink();
          this.showPlayer(false)
        }
      } else {
        this.songList.splice(index, 1)
      }
      localStorage.setItem("playlist", JSON.stringify(this.songList));
    },
    addToList() {
      this.$refs.playlistPicker.show(this.currentSong);
    },
    share() {
      console.log(process.env.VUE_APP_H5)
      common.share(this.currentSong.artistName + ' ' + this.currentSong.songName + ' ' + process.env.VUE_APP_H5 + '/share_player?resType=3&resId=' + this.currentSong.uid, this)
    },
    goBack() {
      if (this.expandShow) {
        this.shrink()
        history.pushState(null, null, document.URL);
      }
    },
    onPlaylistScroll(event) {
      console.log(event.target.scrollTop)
    },
    setSelectMod() {
      let checkBoxEles = this.$refs.playlist.querySelectorAll('.van-checkbox__icon');
      for (const ele of checkBoxEles) {
        ele.style.display = this.selectMode ? 'block' : 'none';
      }
    },
    batchSelect() {
      //  批量选择
      this.selectMode = true
    },
    deleteSelected() {
      //先排序，再倒序，因为先删除小的会影响大的index
      let selectedIndexs = this.checkResult.sort().reverse()
      for (const v of selectedIndexs) {
        this.deleteItem(v);
      }
      this.checkResult = []
      this.selectMode = false
    },
    selectAll() {
      this.$refs.checkboxGroup.toggleAll(true);
    },
    toggleAll() {
      this.$refs.checkboxGroup.toggleAll();
    },
    onResume() {
      console.log("tabbar onResume")
      // if(this.playerState===2) {
      //   this.player.playVideo();
      // }
    },
    savePlayPosition() {
      console.log('savePlayPosition')
      if (this.player !== null && this.player !== undefined) {
        if (this.player.getPlayerState() === 1) {
          let position = {index: this.currentIndex, time: this.player.getCurrentTime()}
          localStorage.setItem('playPosition', JSON.stringify(position))
        }
      }
    },
    showWindowPlayer() {
      common.showWindowPlayer()
    },
    onStart() {
      console.log("tabbar onStart")
      //从小窗回来，重新加载播放进度
      let positionReload = false
      let positionStr = localStorage.getItem('playPosition')
      if (positionStr !== null) {
        console.log(positionStr)
        let position = JSON.parse(positionStr);
        this.currentIndex = position.index
        this.currentSong = this.songList[this.currentIndex]
        this.currentTime = position.time;
        positionReload = true
      }
      if (this.playerState === 2) {
        if (positionReload) {
          this.play()
        } else {
          this.player.playVideo();
        }
      }
    },
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'my-icon';
  src: url('../assets/iconfont.ttf') format('truetype');
}

.my-icon {
  font-family: 'my-icon';
  font-style: normal;
}

.my-icon-singer::before {
  content: '\e610';
}

.my-icon-share::before {
  content: '\e601';
}

.my-icon-fav::before {
  content: '\e86d';
}

.my-icon-float::before {
  content: '\e72b';
}

.my-icon-shrink::before {
  content: '\e797';
}

.my-icon-next::before {
  content: '\ea7f';
}

.my-icon-previous::before {
  content: '\ea87';
}


.my-icon-share:active, .my-icon-fav:active, .my-icon-float:active, .my-icon-shrink:active, .van-icon:active {
  color: #DCDEE0;
}

#player_control {
  width: 100%;
  text-align: left;
  background-color: #F7F8FA;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  border-top: 2px solid #DCDEE0;
}

#player_control-expand {
  width: 100%;
  display: block;
  background-color: #FFFFFF;
  min-height: 200px;
  height: 100%;
}

.player_layer {
  display: inherit;
  width: 120px;
}

.player_layer-expand {
  display: inherit;
  width: 100%;
}

#miniYtPlayer {
  position: absolute;
}

.mask_layer {
  height: 60px;
  width: 106px;
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
}

.mask_layer-expand {
  display: none;
}

.control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-right: 10px;
}

.control-expand {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-right: 10px;
}

.play_info {
  display: flex;
  margin: 10px 5px 5px;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.play_info-expand {
  display: flex;
  margin: 0 10px;
  width: 100%;
  flex-direction: column;
  text-align: left;
}

.song_info {
  color: #323233;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.song_info-expand {
  color: #323233;
  font-size: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.artist_info {
  color: #969799;
  font-size: 0.6rem;
}

.artist_info-expand {
  color: #969799;
  font-size: 0.8rem;
}

.tabbar_player {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 999;
  transition: top .5s;
}

.tabbar_player.expand {
  top: 0;
}

.van-tabbar--fixed {
  position: relative;
}

#big_player {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.play_button {
  display: flex;
  flex-direction: row;
}

.play_button-expand {
  display: none;
}

.action {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 2rem;
}

.playlist {
  text-align: left;
  padding: 0 10px;
}

.playlist .title {
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.checkbox_list {
  height: 100%;
  overflow: auto;
}

.song_select {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>