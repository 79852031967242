
let message = {
    "tabbar.discover": "发现",
    "tabbar.singer": "歌手",
    "tabbar.account": "我的",
    "tabbar.playlist": "歌单",
    "locale.auto": "系统",
    "locale.en": "English",
    "locale.zh-hans": "简体中文",
    "locale.zh-hant": "繁体中文",
    "locale.ja": "日语",
    "index.title": "Hello i18n",
    "index.home": "主页",
    "index.component": "组件",
    "index.api": "API",
    "index.schema": "Schema",
    "index.demo": "uni-app 国际化演示",
    "index.demo-description": "包含 uni-framework、manifest.json、pages.json、tabbar、页面、组件、API、Schema",
    "index.detail": "详情",
    "index.language": "语言",
    "index.language-info": "语言信息",
    "index.system-language": "系统语言",
    "index.application-language": "应用语言",
    "index.language-change-confirm": "应用此设置将重启App",
    "api.message": "提示",
    "schema.name": "姓名",
    "schema.add": "新增",
    "schema.add-success": "新增成功",
    "login.login": "登录",
    "account.share":"分享给好友",
    "account.rate":"给我好评",
    "account.share_text":"快来下载Best MV,免费在线观看全球歌手MV",
    "account.feedback":"意见反馈",
    "player.fullscreen":"旋转手机，即可全屏观看",
    "singer.area_hint":"地区/语言",
    "singer.area_all":"全部语言",
    "singer.area_chinese":"华语",
    "singer.area_english":"英语",
    "singer.area_japanese":"日语",
    "singer.area_korean":"韩语",
    "singer.sex_hint":"性别",
    "singer.sex_all":"全部性别",
    "singer.sex_male":"男",
    "singer.sex_female":"女",
    "singer.sex_group":"组合",
    "loading":"加载中...",
    "readmore.closeText":"展开阅读全文",
    "artist.intro":"歌手简介",
    "artist.song":"歌曲清单",
    "search.hint":"请输入歌名或歌手名称",
    "search.tab_artist":"歌手",
    "search.tab_album":"专辑",
    "search.tab_song":"歌曲",
    "list.no_more":"沒有更多了",
    "confirm":"确认",
    "cancel":"取消",
    "delete":"删除",
    "copy":"复制",
    "playlist.name":"名称",
    "playlist.placeholder":"请输入歌单名称",
    "playlist.songs_count":"首歌曲",
    "login.need_login":"需要登录",
    "login.need_login_desc":"需要登录才能使用该功能",
    "my.footprint":"足迹",
    "my.footprint_value":"观看历史",
    "my.share":"分享",
    "my.share_value":"分享此app给好友",
    "my.rate":"评分",
    "my.rate_value":"去应用市场评分",
    "my.setting":"设置",
    "my.share_msg":"饭团音乐, 免费在线音乐、MV https://ftmusic.pocketdigi.com",
    "settings.about":"关于",
    "settings.language":"语言",
    "settings.exit_account":"退出账号",
    "settings.delete_account":"删除账号",
    "settings.delete_warning":"删除账号后，所有您的个人信息将会抹除且无法恢复，包括播放记录、歌单，是否确认删除？",

    "settings.privacy_policy":"隐私政策",
    "album.desc":"专辑介绍",
    "album.songs":"曲目",
    "pull_refresh.pulling-text":"下拉刷新",
    "pull_refresh.loosing-text":"释放刷新",
    "pull_refresh.loading-text":"加载中",
    "discover.host-song":"大家都在听",
    "discover.play-all":"播放全部",
    "discover.rec-playlist":"推荐歌单",
    "player.batch-operation":"批量操作",
    "player.select-all":"全选",
    "player.select-toggle":"反选",
    "player.random":"随机播放",
    "player.play":"播放",
    "account.mv-first":"MV优先",
    "account.mv-first-label":"有MV时优先播放MV",
    "playlist.title":"歌单",
    "playlist.default-list":"默认",
    "playlist.create":"创建歌单",
    "edit":"编辑",
    "playlist.edit-fail":"编辑失败,请确认名称是否冲突",
    "playlist.create-fail":"创建失败,请确认名称是否冲突",
    "app.name":"饭团音乐",
    "youtube.network-fail":"当前网络无法访问Youtube，音乐无法正常播放，请检查网络",
    "down.hint":"该功能仅在 App 端可用，请下载 App",
    "down":"下载",
    "down.system-browser":"如点击无法下载，请使用系统浏览器打开本页面。仅限Android操作系统, iOS 版本开发中",
    "player.loading":"播放器正在加载，请稍候"
}

export default message;