<template>
  <div class="list_item" @click="onClick">
    <van-image
        width="120"
        height="67.5"
        fit="cover"
        lazy-load
        :src="item.thumbnail"
    />
    <div class="info">
      <div class="title">{{item.songName}}</div>
      <div class="subtitle">{{item.artistName}}</div>
    </div>
    <div class="playing" v-if="playing">
      <van-icon name="music" size="30"/>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => undefined
    },
    clickPlay: {
      type: Boolean,
      default:() => false
    },
    playing: {
      type: Boolean,
      default:() => false
    }
  },
  name: "SongItem",
  methods: {
    onClick() {
      if(this.clickPlay) {
        this.$eventBus.$emitReady('addSong', {resType: 3, resId: this.item.uid })
        return
      }
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
.list_item {
  display: flex;
  padding: 5px 0;
  background-color: #FFFFFF;
  justify-content: space-between;
}
.title,.subtitle{
  font-size: 0.9rem;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.subtitle {
  font-size: 0.8rem;
  color: #969799;
}
.info {
  width: 100%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.playing {

}
.playing .van-icon{
  -webkit-animation:myRotate 5s linear infinite;
  animation:myRotate 5s linear infinite;
}
@-webkit-keyframes myRotate{
  0%{ -webkit-transform: rotate(0deg);}
  50%{ -webkit-transform: rotate(180deg);}
  100%{ -webkit-transform: rotate(360deg);}
}
@keyframes myRotate{
  0%{ -webkit-transform: rotate(0deg);}
  50%{ -webkit-transform: rotate(180deg);}
  100%{ -webkit-transform: rotate(360deg);}
}
.van-image__img {
  width: auto;
}

</style>