
let message = {
    "tabbar.discover": "Discover",
    "tabbar.singer": "Singer",
    "tabbar.account": "Account",
    "tabbar.playlist": "PlayList",
    "locale.auto": "System",
    "locale.en": "English",
    "locale.zh-hans": "简体中文",
    "locale.zh-hant": "繁体中文",
    "locale.ja": "日语",
    "index.title": "Hello i18n",
    "index.home": "Home",
    "index.component": "Component",
    "index.api": "API",
    "index.schema": "Schema",
    "index.demo": "uni-app globalization",
    "index.demo-description": "Include uni-framework, manifest.json, pages.json, tabbar, Page, Component, API, Schema",
    "index.detail": "Detail",
    "index.language": "Language",
    "index.language-info": "Settings",
    "index.system-language": "System language",
    "index.application-language": "Application language",
    "index.language-change-confirm": "Applying this setting will restart the app",
    "api.message": "Message",
    "schema.name": "Name",
    "schema.add": "Add",
    "schema.add-success": "Add success",
    "login.login": "Login",
    "account.share":"Share to friends",
    "account.rate":"Give me five star",
    "account.share_text":"Download Best MV now,watch music video all over the world for free!",
    "account.feedback":"Feedback",
    "player.fullscreen":"Rotate the phone to watch it in full screen",
    "singer.area_hint":"Language",
    "singer.area_all":"All Language",
    "singer.area_chinese":"Chinese",
    "singer.area_english":"English",
    "singer.area_japanese":"Japanese",
    "singer.area_korean":"Korean",
    "singer.sex_hint":"Sex",
    "singer.sex_all":"All Genders",
    "singer.sex_male":"Male",
    "singer.sex_female":"Female",
    "singer.sex_group":"Group",
    "loading":"Loading...",
    "readmore.closeText":"Read More",
    "artist.intro":"Artist Intro",
    "artist.song":"Song list",
    "search.hint":"Song name or artist name",
    "search.tab_artist":"Artist",
    "search.tab_album":"Album",
    "search.tab_song":"Song",
    "list.no_more":"No more",
    "confirm":"Confirm",
    "cancel":"Cancel",
    "copy":"Copy",
    "playlist.name":"Name",
    "playlist.placeholder":"Input playlist name",
    "playlist.songs_count":" songs",
    "login.need_login":"Login required",
    "login.need_login_desc":"You need to log in to use this feature",
    "my.footprint":"Footprint",
    "my.footprint_value":"View history",
    "my.share":"Share",
    "my.share_value":"Share me to friends",
    "my.rate":"Rate",
    "my.rate_value":"Give me five star",
    "my.setting":"Settings",
    "my.share_msg":"FanTuan Music, Free Music、MV. https://ftmusic.pocketdigi.com",
    "settings.about":"About",
    "settings.language":"Language",
    "settings.exit_account":"Logout",
    "settings.delete_account":"Delete Account",
    "settings.delete_warning":"After deleting the account, all your personal information will be erased and cannot be restored, including play history, play lists," +
        "Do you sure you want to delete it？",
    "settings.privacy_policy":"Privacy Policy",
    "album.desc":"Description",
    "album.songs":"Tracks",
    "pull_refresh.pulling-text":"Pull to refresh",
    "pull_refresh.loosing-text":"Release to refresh",
    "pull_refresh.loading-text":"Loading",
    "discover.host-song":"Hot Songs",
    "discover.play-all":"Play all",
    "discover.rec-playlist":"Recommend Playlist",
    "player.batch-operation":"Batch Operation",
    "player.select-all":"Select All",
    "player.select-toggle":"Inverse",
    "player.random":"Shuffle",
    "player.play":"Play",
    "account.mv-first":"MV first",
    "account.mv-first-label":"When there is a MV, play the MV first",
    "playlist.title":"Playlist",
    "playlist.default-list":"Default",
    "playlist.create":"Create New Playlist",
    "edit":"Edit",
    "playlist.edit-fail":"Editing Failed,please confirm whether the name conflicts",
    "playlist.create-fail":"Creation failed, please confirm whether the name conflicts",
    "app.name":"FanTuan Music",
    "youtube.network-fail":"Cannot access YouTube,the music cannot be played,please check the network",
    "down.hint":"This feature only available in FanTuan Music App，Please download and install it.",
    "down":"Download",
    "down.system-browser":"If cannot download, please open this page in system browser. Android only, iOS version is developing",
    "player.loading":"Please wait while the player is loading"
}

export default message;