
let message = {
    "tabbar.discover": "推薦",
    "tabbar.singer": "歌手",
    "tabbar.account": "我的",
    "tabbar.playlist": "歌單",
    "locale.auto": "系統",
    "locale.en": "English",
    "locale.zh-hans": "简体中文",
    "locale.zh-hant": "繁體中文",
    "locale.ja": "日语",
    "index.title": "Hello i18n",
    "index.home": "主頁",
    "index.component": "組件",
    "index.api": "API",
    "index.schema": "Schema",
    "index.demo": "uni-app 國際化演示",
    "index.demo-description": "包含 uni-framework、manifest.json、pages.json、tabbar、頁面、組件、API、Schema",
    "index.detail": "詳情",
    "index.language": "語言",
    "index.language-info": "語言信息",
    "index.system-language": "系統語言",
    "index.application-language": "應用語言",
    "index.language-change-confirm": "應用此設置將重啟App",
    "api.message": "提示",
    "schema.name": "姓名",
    "schema.add": "新增",
    "schema.add-success": "新增成功",
    "login.login": "登錄",
    "account.share":"分享給好友",
    "account.rate":"給我好評",
    "account.share_text":"快來下載Best MV,免費在線觀看全球歌手MV",
    "account.feedback":"意見反饋",
    "player.fullscreen":"旋轉手機，即可全屏觀看",
    "singer.area_hint":"地區/語言",
    "singer.area_all":"全部語言",
    "singer.area_chinese":"華語",
    "singer.area_english":"英語",
    "singer.area_japanese":"日語",
    "singer.area_korean":"韓語",
    "singer.sex_hint":"性别",
    "singer.sex_all":"全部性别",
    "singer.sex_male":"男",
    "singer.sex_female":"女",
    "singer.sex_group":"組合",
    "loading":"加載中...",
    "readmore.closeText":"展開閱讀全文",
    "artist.intro":"歌手簡介",
    "artist.song":"歌曲清單",
    "search.hint":"請輸入歌名或歌手名稱",
    "search.tab_artist":"歌手",
    "search.tab_album":"專輯",
    "search.tab_song":"歌曲",
    "list.no_more":"没有更多了",
    "confirm":"確認",
    "cancel":"取消",
    "delete":"刪除",
    "copy":"複製",
    "playlist.name":"名稱",
    "playlist.placeholder":"請輸入歌單名稱",
    "login.need_login":"需要登錄",
    "login.need_login_desc":"需要登錄才能使用該功能",
    "my.footprint":"足跡",
    "my.footprint_value":"觀看記錄",
    "my.share":"分享",
    "my.share_value":"分享此app給好友",
    "my.rate":"評分",
    "my.rate_value":"去應用市場評分",
    "my.setting":"設置",
    "my.share_msg":"飯糰音樂, 免費在線音樂、MV  https://ftmusic.pocketdigi.com",
    "settings.about":"關於",
    "settings.language":"語言",
    "settings.exit_account":"退出帳號",
    "settings.delete_account":"刪除賬號",
    "settings.delete_warning":"刪除賬號後，所有您的個人信息將會抹除且無法恢復，包括播放記錄、歌單，是否確認刪除？",
    "settings.privacy_policy":"隱私政策",
    "album.desc":"專輯簡介",
    "album.songs":"曲目",
    "pull_refresh.pulling-text":"下拉刷新",
    "pull_refresh.loosing-text":"釋放刷新",
    "pull_refresh.loading-text":"加載中",
    "discover.host-song":"大家都在聽",
    "discover.play-all":"播放全部",
    "discover.rec-playlist":"推薦歌單",
    "player.batch-operation":"批量操作",
    "player.select-all":"全選",
    "player.select-toggle":"反選",
    "player.random":"隨機播放",
    "player.play":"播放",
    "account.mv-first":"MV優先",
    "account.mv-first-label":"有MV時優先播放MV",
    "playlist.title":"歌單",
    "playlist.default-list":"默認",
    "playlist.create":"創建歌單",
    "edit":"編輯",
    "playlist.edit-fail":"編輯失敗,請確認名稱是否衝突",
    "playlist.create-fail":"創建失敗,請確認名稱是否衝突",
    "app.name":"飯糰音樂",
    "youtube.network-fail":"當前網絡無法訪問Youtube，音樂無法正常播放，請檢查網絡",
    "down.hint":"該功能僅在 App 端可用，請下載 App",
    "down":"下載",
    "down.system-browser":"如點擊無法下載，請使用系統瀏覽器打開本頁面。僅限Android操作系統, iOS 版本開發中。",
    "player.loading":"播放器正在加載，請稍候"
}

export default message;