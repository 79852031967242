import axios from 'axios';
import common from "@/common/common";

export default {
    install: function (Vue,) {
        Vue.prototype._post = function (path, data, callback) {
            let _this = this;

            let address = process.env.VUE_APP_SERVER;
            axios
                .post(address + path, data, getConfig())
                .then(response => {
                    respHandler(response, callback, _this);
                })
                .catch(function (error) {
                    errorHandler(error, callback, _this);
                });
        };
        Vue.prototype._put = function (path, data, callback) {
            let _this = this;

            let address = process.env.VUE_APP_SERVER;
            axios
                .put(address + path, data, getConfig())
                .then(response => {
                    respHandler(response, callback, _this);
                })
                .catch(function (error) {
                    errorHandler(error, callback, _this);
                });
        };
        Vue.prototype._get = function (path, callback, errorCallback) {
            let _this = this;
            let address = process.env.VUE_APP_SERVER;

            axios
                .get(address + path, getConfig())
                .then(response => {
                    respHandler(response, callback, _this);
                })
                .catch(function (error) {
                    if (errorCallback != null) {
                        errorCallback(error)
                    } else {
                        errorHandler(error, callback, _this);
                    }
                });
        };
        Vue.prototype._delete = function (path, callback, errorCallback) {
            let _this = this;
            let address = process.env.VUE_APP_SERVER;

            axios.delete(address + path, getConfig())
                .then(response => {
                    respHandler(response, callback, _this);
                })
                .catch(function (error) {
                    if (errorCallback != null) {
                        errorCallback(error)
                    } else {
                        errorHandler(error, callback, _this);
                    }
                });

        };
        Vue.prototype._patch = function (path, data, callback) {
            let _this = this;
            let address = process.env.VUE_APP_SERVER;

            axios
                .patch(address + path, data, getConfig())
                .then(response => {
                    respHandler(response, callback, _this);
                })
                .catch(function (error) {
                    errorHandler(error, callback, _this);
                });
        };

        function respHandler(response, callback,_this) {
            if(response.data.code === 401) {
                common.goLogin()
                return
            }
            if (callback) {
                callback(response);
            }

        }

        function errorHandler(error, callback, _this) {
            console.log(error)
            if (callback) {
                callback(error.response);
            }

            // if(status === 401) {
            //     //未登录
            //     Dialog.alert({
            //         title: _this.$t('login.need_login'),
            //         message: _this.$t('login.need_login_desc'),
            //         confirmButtonText:_this.$t('confirm')
            //     }).then(() => {
            //         // 跳转到登录
            //         common.goLogin();
            //     });
            // }
        }

        function getConfig() {
            let config = {};
            config.headers = {};
            let token = common.getToken();
            if (token !== undefined && token !== null) {
                config.headers.token = common.getToken();
            }
            config.headers.web_version = process.env.version;
            config.headers.appLanguage = common.getLang();
            return config;
        }

    }
}
