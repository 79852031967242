<template>
  <van-dialog v-model="showCreate" :title="$t('playlist.create')" @confirm="onCreateSubmit" show-cancel-button>
    <van-cell-group style="margin: 20px 0">
      <van-field v-model="playlist.listName" label-width="4rem" :label="$t('playlist.name')" :placeholder="$t('playlist.placeholder')" />
    </van-cell-group>
  </van-dialog>
</template>

<script>
import { Toast } from 'vant';

export default {
  name: "CreatePlaylistDialog",
  data() {
    return {
      showCreate: false,
      playlist: {
        listName: '',
        uid: null
      }
    }
  },
  methods: {
    showDialog(playlist){
      this.showCreate = true
      if(playlist!==null) {
        this.playlist.uid = playlist.uid
        this.playlist.listName = playlist.listName
      }else{
        this.playlist.uid = null
        this.playlist.listName = ''
      }
    },
    onCreateSubmit() {
      if(this.playlist.uid) {
        this._put('/bestmv/playlist', this.playlist, (resp) => {
          this.showCreate = false;
          let result = resp.data;
          this.playlist.listName='';
          if (result.code !== 200) {
            Toast.fail(this.$t('playlist.edit-fail'));
            this.$emit('create-failed')
            return
          }
          this.$emit('created')
        })
      } else {
        this._post('/bestmv/playlist', this.playlist, (resp) => {
          this.showCreate = false;
          let result = resp.data;
          this.playlist.listName='';
          if (result.code !== 200) {
            Toast.fail(this.$t('playlist.create-fail'));
            this.$emit('create-failed')
            return
          }
          this.$emit('created')
        })
      }

    }
  }
}
</script>

<style scoped>

</style>